import Page from 'components/Page';
import MaterialTable from 'material-table';
import React from 'react';
import { Modal } from 'react-bootstrap';
import NotificationSystem from 'react-notification-system';
import { Button, Col, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import api from '../../services/api';
import MessageUtil from '../../utils/alert';
import { NOTIFICATION_SYSTEM_STYLE, RELATORIO_POCOS } from '../../utils/constants';
import { DateUtil } from '../../utils/date';

import { MdEdit } from 'react-icons/md';
import { isObjectAndStringify } from '../../utils/utils';

export default class RelatorioListPage extends React.Component {

    state = {
        redirectRow: false,
        redirectEdit: false,
        rowToEdit: '',
        rowClicked: '',

        /*Modal insert observation*/
        mPorta: '',
        mEquipamento: '',
        mDataLeitura: '',
        mDataLeituraFormatada: '',
        mObservacao: '',
        mDadoDuvidosoId: 0,
        mUsuario: '',

        show: false,
        tipo: '5', //''
        filtroTemporal: 'Diário',
        mensalOuDiario: 'Diário',
        dataInicial: '2022-06-20 00:04:59',
        loading: false,
        dataFinal: '2022-06-22 23:55:01',
        equipamentoId: '2',
        vazaoOutorga: '',
        tempoBombaLigada: '',
        hidrometro: '',
        fatorOutorgaHidro: '',
        uf: '',
        tipofirmware: '',
        equipaments: [],
        dadoDuvidoso: "", /*{ porta: '', equipamento: '', data: '', observacao: '', usuario: '' },*/
        relatorioColumn: [
            {
                title: 'Equipamento', field: 'nomeEquipamento'
            }, {
                title: 'Quantidade', field: 'quantidade'
            }, {
                title: 'Data', field: 'dataOcorrido',
                render: rowData => <span>{this.formatDate(rowData.dataOcorrido)}</span>
            }
        ],
        relatorioResultado: []
    }

    componentWillMount() {
        // console.log('Carregamento WillMount ;) -> ', this.state);
        this.getAllEquipaments();
    }

    getAllEquipaments = () => {
        api.get('/equipamento/all').then((response) => {
            isObjectAndStringify(response);
            // console.log('Carregamento Todos Equipamentos ;) -> ', response.data)
            this.setState({
                equipaments: response.data,
            });
        }).catch((error) => {
            console.log(error);
        });
    }


    formatDate(data) {
        var dia = data.substring(8, 10);
        var mes = data.substring(5, 7);
        var ano = data.substring(0, 4);

        if (this.state.mensalOuDiario === 'Diário')
            return dia + "/" + mes + "/" + ano

        switch (mes) {
            case "01":
                mes = "Janeiro";
                break;
            case "02":
                mes = "Fevereiro";
                break;
            case "03":
                mes = "Março";
                break;
            case "04":
                mes = "Abril";
                break;
            case "05":
                mes = "Maio";
                break;
            case "06":
                mes = "Junho";
                break;
            case "07":
                mes = "Julho";
                break;
            case "08":
                mes = "Agosto";
                break;
            case "09":
                mes = "Setembro";
                break;
            case "10":
                mes = "Outubro";
                break;
            case "11":
                mes = "Novembro";
                break;
            case "12":
                mes = "Dezembro";
                break;
            default:
                break;

        }

        return mes + "/" + ano;

    }

    validacao() {
        var estaValido = true;

        if (this.state.tipo === RELATORIO_POCOS && this.state.filtroTemporal === 'Mensal') {
            MessageUtil.showMsg(this.notificationSystem, 401, 'Relatório apenas diário.');
            estaValido = false;
        }

        return estaValido;
    }

    gerar() {
        let { relatorioColumn, relatorioResultado, equipaments, loading, vazaoOutorga, tempoBombaLigada, ...payload } = this.state;

        if (!this.validacao()) return;

        this.setState({ loading: true, relatorioResultado: [] });

        var vazaoOutorgaEncontrado = ''
        var tempoBombaLigadaEncontrado = ''
        var hidrometroEncontrado = ''
        var fatorOutorgaHidroEncontrado = ''
        var ufEncontrado = ''
        var firmwareEquipamentoEncontrado = ''

        if (this.state.tipo === RELATORIO_POCOS) {
            for (let index = 0; index < this.state.equipaments.length; index++) {
                if (parseInt(this.state.equipamentoId) === this.state.equipaments[index].idEquipamento) {
                    // console.log('Equipamento ;) ->', this.state.equipaments[index])
                    vazaoOutorgaEncontrado = this.state.equipaments[index].vazaoOutorga;
                    tempoBombaLigadaEncontrado = this.state.equipaments[index].tempoBombaLigada;
                    hidrometroEncontrado = this.state.equipaments[index].outorgaHidro;
                    fatorOutorgaHidroEncontrado = this.state.equipaments[index].fatorOutorgaHidro;
                    ufEncontrado = this.state.equipaments[index].uf
                    firmwareEquipamentoEncontrado = this.state.equipaments[index].firmwareEquipamento;
                    break;
                }
            }
        }

        // console.log('payload', payload)
        //120000
        // api.post("/relatorio/buscar", payload, { timeout: 0 }) 
        api.post("/relatorio/gerar", payload, { timeout: 0 })
            .then(async (response) => {
                if (this.notificationSystem !== undefined) {
                    MessageUtil.showMsg(this.notificationSystem, 200);
                    if (response.data.length === 0)
                        MessageUtil.showMsg(this.notificationSystem, 404, "Nenhum dado localizada neste período");
                }

                const ajusteData = response.data.map((item, index, array) => ({
                    ...item,
                    hidInicialComVirgula: index === 0 ? item.hidInicialComVirgula : array[index - 1].hidFinalComVirgula,
                }));

                if (response.data !== null) {
                    // console.log('Response Data', response.data)
                    for (let index = 0; index < response.data.length; index++) {
                        // const item = response.data[index];
                        if (response.data[index].dadoDuvidoso !== "") {
                            const id = parseInt(response.data[index].dadoDuvidoso, 10)
                            let dado = await api.get(`/dado-duvidoso/${id}`)
                                .then((resp) => {
                                    isObjectAndStringify(resp);
                                    // console.log('Resp', resp)
                                    if (resp.data)
                                        return resp.data
                                })
                                .catch((err) => {
                                    console.log('Error :(', err)
                                })
                                .finally(() => {
                                    // console.log('RESPONSE: ', response.data)
                                })
                            response.data[index].dadoDuvidoso = response.data[index].dadoDuvidoso + '#&$@#' + dado.dadoObservacao
                        }

                        let tipoAlarme = ''
                        if (ufEncontrado === 'SP') {
                            // Estouro de outorga em MG/SP
                            if (response.data[index].volume > hidrometroEncontrado) {
                                // estourou autorga
                                tipoAlarme = 'Volume'
                            }
                        } else {
                            // Estouro de outorga em MG
                            if (response.data[index].horimetro > tempoBombaLigadaEncontrado && response.data[index].vazao > vazaoOutorgaEncontrado) {
                                // estourou autorga
                                tipoAlarme = 'Tempo/Vazão'
                            } else if (response.data[index].horimetro > tempoBombaLigadaEncontrado) {
                                // estourou autorga
                                tipoAlarme = 'Tempo'
                            } else if (response.data[index].vazao > vazaoOutorgaEncontrado) {
                                // estourou autorga
                                tipoAlarme = 'Vazão'
                            }
                        }
                        response.data[index] = {
                            ...response.data[index],
                            alarme: tipoAlarme,
                        }
                    }
                }

                this.setState({
                    relatorioResultado: (ajusteData !== null) ? ajusteData : [],
                    vazaoOutorga: vazaoOutorgaEncontrado,
                    tempoBombaLigada: this.buscaLetraFirmware(firmwareEquipamentoEncontrado) !== 'M' ? tempoBombaLigadaEncontrado : tempoBombaLigadaEncontrado * 60,
                    hidrometro: hidrometroEncontrado,
                    fatorOutorgaHidro: fatorOutorgaHidroEncontrado,
                    uf: ufEncontrado,
                    mensalOuDiario: payload.filtroTemporal,
                    tipofirmware: firmwareEquipamentoEncontrado,
                    relatorioColumn: this.checkColumns(payload.tipo)
                })
            }).catch(error => {
                if (error.response) {
                    if (this.notificationSystem !== undefined)
                        MessageUtil.showMsg(this.notificationSystem, error.response.data);
                }
                else {
                    if (this.notificationSystem !== undefined)
                        MessageUtil.showMsg(this.notificationSystem, 401, 'Erro ao gerar relatório. Tente novamente.');
                }
            }).finally(() => {
                this.setState({ loading: false });
            });

    }

    checkColumns(tipo) {
        // if (tipo === '2') {
        //     return [{
        //         title: 'Equipamento', field: 'equipamento'
        //     }, {
        //         title: 'Tempo real [s]', field: 'tempoReal'
        //     }, {
        //         title: 'Tempo previsto [s]', field: 'tempoPrevisto'
        //     }, {
        //         title: 'Diferença [s]', field: 'diferenca',
        //         render: rowData => this.checkDif(rowData.diferenca)
        //     }, {
        //         title: 'Data', field: 'dataLeitura',
        //         render: rowData => <span>{this.formatDate(rowData.dataLeitura)}</span>
        //     }]

        // }
        // else 
        if (tipo === RELATORIO_POCOS) {
            return [{
                title: '', field: 'editar', export: false,
                render: rowData => {
                    return (
                        <MdEdit onClick={() => this.handleEdit(rowData)} />
                    )
                }
            }, {
                title: 'Hid. Inicial [m³]', field: 'hidInicialComVirgula'
            }, {
                title: 'Hid. Final [m³]', field: 'hidFinalComVirgula'
            }, {
                title: 'Volume [m³]', field: 'volumeComVirgula',
                render: rowData => this.checkVolume(rowData)
            }, {
                title: 'Vazão [m³/h]', field: 'vazaoComVirgula',
                render: rowData => this.checkVazaoOutorga(rowData)
            }, {
                title: 'Horímetro [HH:MM:SS]', field: 'horimetroComVirgula',
                render: rowData => this.checkTempoBombaLigada(rowData.horimetro)
            }, {
                title: 'Nível Poço (Estático) [m]', field: 'nivelPocoMinComVirgula'
            }, {
                title: 'Nível Poço (Dinâmico) [m]', field: 'nivelPocoMaxComVirgula'
            }, {
                title: 'Data', field: 'dataLeituraFormatada'
            }, {
                title: 'Dado Duvidoso', field: 'dadoDuvidoso', export: false,
                render: rowData => this.checkDadoDuvidoso(rowData),
                valueFormatter: ({ value }) => value !== '' ? value : '---',
            }, {
                title: 'Alarme', field: 'alarme', export: false, hidden: true
            }
            ]

        }

        return [{
            title: 'Equipamento', field: 'nomeEquipamento'
        }, {
            title: 'Quantidade', field: 'quantidade'
        }, {
            title: 'Data', field: 'dataOcorrido',
            render: rowData => <span>{this.formatDate(rowData.dataOcorrido)}</span>
        }]
    }

    calcOutorgaToGet = (outorgaHidro, fatorOutorgaHidro) => {
        return (outorgaHidro * fatorOutorgaHidro).toFixed(2).toString().replace(".", ",");
    }

    checkDif(dif) {
        if (dif < 0) {
            // estourou autorga
            return <span style={{ color: 'red' }}>{dif}</span>
        }

        return <span>{dif}</span>
    }

    // handleEdit = (data) => {
    //     console.log("Editar " + data + " Porta " + data.idPorta);
    //     this.setState({ dadoDuvidoso: { porta: data.idPorta, equipamento: data.idEquipamento } });
    //     // setTimeout(() => {
    //     console.log("Dado Duvidoso > ", this.state)
    //     // this.handleShow()
    //     // }, 3000);
    // }

    renderModalObservacao = {
        onClick: (row) => {
            alert(row)
        }
        // if (this.state.dadoDuvidoso !== null) {
        //     return (
        //         <Page className="DadoDuvidosoViewPage" title="">
        //             <Modal show={true} aria-labelledby="contained-modal-title-vcenter" centered>
        //                 <Modal.Body>
        //                     <Row>
        //                         <Col lg={9} md={9} sm={9} xs={9}>
        //                             <h2>Carregando...</h2>
        //                         </Col>
        //                         <Col lg={3} md={3} sm={3} xs={3}>
        //                             {this.state.dadoDuvidoso}
        //                         </Col>
        //                     </Row>
        //                 </Modal.Body>
        //             </Modal>
        //         </Page>
        //     );
        // }
    }

    handleClose = () => {
        this.setState({ show: false });
    }

    handleShow = () => {
        this.setState({ show: true });
    }

    handleSaveClose = async (event) => {
        event.preventDefault(event);

        if (this.state.mDadoDuvidosoId !== 0) {
            await api.get(`/dado-duvidoso/${this.state.mDadoDuvidosoId}`)
                .then(async (response) => {
                    if (response) {
                        await api.delete(`/dado-duvidoso/${response.data.idDadoDuvidoso}`)
                            .then((resp) => {
                                if (this.notificationSystem !== undefined) {
                                    MessageUtil.showMsg(this.notificationSystem, 200);
                                    this.toggle();
                                    this.gerar();
                                }
                            }).catch((error) => {
                                console.log('Erro ao deletar dado duvidoso', error.resp);
                                if (this.notificationSystem !== undefined)
                                    MessageUtil.showMsg(this.notificationSystem, error.resp.status);
                            });
                    }
                }).catch((error) => {
                    console.log('Erro ao verificar existência do dado duvidoso', error.response);
                    if (this.notificationSystem !== undefined)
                        MessageUtil.showMsg(this.notificationSystem, error.response.status);
                });
        } else {
            api.post('/dado-duvidoso/save', {
                dadoObservacao: event.target.mObservacao.value,
                idEquipamento: this.state.equipamentoId,
                dadoData: this.state.mDataLeitura,
            }, { timeout: 0 }).then((response) => {
                if (this.notificationSystem !== undefined) {
                    MessageUtil.showMsg(this.notificationSystem, 200);
                    this.toggle();
                    this.gerar();
                }
            }).catch((error) => {
                console.log('Erro ao salvar mensagem de observação', error.response);
                if (this.notificationSystem !== undefined)
                    MessageUtil.showMsg(this.notificationSystem, error.response.status);
                this.toggle();
            });
        }

        setTimeout(() => {
            console.log('Estado', this.state);
        }, 3000);
    }


    //===============================================================================
    //===============================================================================Metodos 

    handleClickTable = (_id) => {
        this.setState({
            rowClicked: _id,
            redirectRow: true,
        });
    }

    handleEdit = async (data) => {
        // console.log('JSON DATA ROW', JSON.stringify(data, null, 2))
        await this.setState({
            show: true,
            mPorta: data.idPorta,
            mEquipamento: data.idEquipamento,
            mObservacao: '',
            mDadoDuvidosoId: data.dadoDuvidoso === '' ? 0 : +data.dadoDuvidoso.split('#&$@#')[0],
            mDataLeitura: data.dataLeitura,
            mDataLeituraFormatada: data.dataLeituraFormatada,
            mUsuario: data.IdUsuario,
            mRow: data.tableData.id
        })
    }

    handleDelete = (_id) => {
        this.setState({
            showModalDelete: true,
            rowClicked: _id,
        });
    }

    toggle = () => {
        this.setState({ show: !this.state.show });
    }

    checkVazaoOutorga(rowData) {
        // Estouro de outorga apenas em MG
        if (this.state.uf === 'MG') {
            if (rowData.vazao > this.state.vazaoOutorga) {
                // estourou autorga
                return <span style={{ color: 'red' }}>{rowData.vazaoComVirgula}</span>
            } else if (rowData.vazao === -100.0) {
                return <span style={{ color: 'red' }}> --- </span>
            }
        } else {
            if (rowData.vazao === -100.0) {
                return '---'
            }
        }
        return <span>{rowData.vazaoComVirgula}</span>
    }

    checkDadoDuvidoso(rowData) {
        if (rowData.dadoDuvidoso !== "") {
            let d = ''
            if (rowData.dadoDuvidoso.split('#&$@#').length > 1) {
                d = rowData.dadoDuvidoso.split('#&$@#')[1]
            } else {
                d = 'SIM'
            }
            return <span style={{ color: 'red' }}> {d} </span>
        } else {
            return <span style={{ color: 'green' }}> --- </span>
        }
    }

    checkTempoBombaLigada(tempo) {
        // Estouro de outorga em MG
        if (this.state.uf === 'MG') {
            if (tempo > this.state.tempoBombaLigada) {
                // estourou autorga
                return <span style={{ color: 'red' }}>{DateUtil.formatSecondsToTimeFormat(tempo)}</span>
            }
        }

        return <span>{DateUtil.formatSecondsToTimeFormat(tempo)}</span>
    }

    checkVolume(rowData) {
        // Estouro de outorga em MG
        if (this.state.uf === 'SP') {
            if (rowData.volume > this.state.hidrometro) {
                // estourou autorga
                return <span style={{ color: 'red' }}>{rowData.volumeComVirgula}</span>
            }
        }

        return <span>{rowData.volumeComVirgula}</span>
    }

    buscaLetraFirmware(firmware) {
        let letra = firmware
        if(letra !== '' && letra !== null && letra !== undefined) {
            letra = letra[letra.length - 1]
        }
        return letra
    }

    render() {
        return (
            <>
                <Page className="RelatorioListPage" title="Relatório de Poços">
                    <FormGroup>
                        <Row>
                            {/* <Col lg={3} md={4} sm={6} xs={12}>
                                <Label for="tipo">Tipo*:</Label>
                                <Input type="select" id="tipo" onChange={(event) => { this.setState({ tipo: event.target.value, relatorioResultado: [] }) }}>
                                    <option value="" >Escolha: </option>
                                    <option value="1">Quedas de Energia</option>
                                    <option value="2">Consumo de Outorga</option>
                                    <option value="3">Acionamento da Botoeira</option>
                                    <option value="4">Nível Crítico</option>
                                    <option value="5">Poços</option>
                                </Input>
                            </Col> */}

                            {this.state.tipo === RELATORIO_POCOS &&
                                <Col lg={3} md={4} sm={6} xs={12}>
                                    <Label for="equipamentoId">Equipamento*:</Label>
                                    <Input type="select" id="equipamentoId" onChange={(event) => { this.setState({ equipamentoId: event.target.value, relatorioResultado: [], vazaoOutorga: '', tempoBombaLigada: '', hidrometro: '', fatorOutorgaHidro: '' }) }}>
                                        {this.state.equipaments.map((equipamento) => {
                                            return <option key={equipamento.idEquipamento} value={equipamento.idEquipamento} >{equipamento.nomeEquipamento} </option>;
                                        })}
                                    </Input>
                                </Col>
                            }


                            {/* <Col lg={3} md={4} sm={6} xs={12}>
                                <Label for="filtroTemporal">Filtro temporal*:</Label>
                                <Input type="select" id="filtroTemporal" onChange={(event) => { this.setState({ filtroTemporal: event.target.value, relatorioResultado: [] }) }}>
                                    <option value="" >Escolha: </option>
                                    <option value="Diário" defaultValue>Diário</option>
                                    {this.state.tipo !== RELATORIO_POCOS &&
                                        <option value="Mensal">Mensal</option>
                                    }
                                </Input>
                            </Col> */}

                            <Col lg={3} md={4} sm={6} xs={12}>
                                <Label for="dataInicial">Data inicial*:</Label>
                                <Input type="date" id="dataInicial" onChange={(event) => { this.setState({ dataInicial: event.target.value + ' 00:05:00', relatorioResultado: [] }) }} />
                            </Col>

                            <Col lg={3} md={4} sm={6} xs={12}>
                                <Label for="dataFinal">Data final*:</Label>
                                <Input type="date" id="dataFinal" onChange={(event) => { this.setState({ dataFinal: event.target.value + ' 23:55:00', relatorioResultado: [] }) }} />
                            </Col>

                        </Row>
                        {this.state.tipo === RELATORIO_POCOS && this.state.hidrometro !== '' && this.state.fatorOutorgaHidro !== '' && this.state.uf === 'SP' &&
                            <><Row>
                                <Col lg={3} md={4} sm={6} xs={12}>
                                    <Label>Outorga:</Label><br />
                                    <Label for="hidrometro">Volume: {this.calcOutorgaToGet(this.state.hidrometro, this.state.fatorOutorgaHidro)} m³/dia</Label>
                                </Col>
                            </Row></>
                        }
                        {this.state.tipo === RELATORIO_POCOS && this.state.vazaoOutorga !== '' && this.state.tempoBombaLigada !== '' && this.state.uf === 'MG' &&
                            <>
                                <Row>
                                    <Col lg={3} md={4} sm={6} xs={12}>
                                        <Label>Outorga:</Label><br />
                                        <Label for="vazaoOutorga">Vazão: {this.state.vazaoOutorga.toString().replace(".", ",")} m³/h</Label><br />
                                        <Label for="tempoBombaLigada">Tempo Bomba Ligada: {DateUtil.formatSecondsToTimeFormat(this.state.tempoBombaLigada)}</Label>
                                    </Col>
                                </Row>
                            </>
                        }
                        <Row>
                            <Col lg={3} md={4} sm={6} xs={12}>
                                <Button onClick={() => { this.gerar() }}>Gerar</Button>
                                {this.state.loading &&
                                    <Spinner animation="border" style={{ marginLeft: 30 }} role="status"></Spinner>
                                }
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <MaterialTable
                                    title=""
                                    columns={this.state.relatorioColumn}
                                    data={this.state.relatorioResultado}
                                    options={{
                                        headerStyle: {
                                            backgroundColor: '#B5B5B5', color: '#ffffff', fontSize: 16
                                        },
                                        exportButton: true,
                                        exportDelimiter: ';',
                                        pageSizeOptions: [10, 50, 100],
                                        pageSize: 10,
                                        exportAllData: true,
                                        exportCsv: (columns, data) => {
                                            const csvData = [];
                                            let colunas = columns.filter((column) => {
                                                if (column.field !== 'editar' && column.field !== 'dadoDuvidoso'){
                                                    return column
                                                }
                                            })

                                            data.forEach((rowData, index) => {
                                                if (rowData.dadoDuvidoso === '') {
                                                    const row = [];
                                                    colunas.forEach((column, index2) => {
                                                        let value = ''

                                                        if(rowData[column.field] === '-100,0') {
                                                            value = 0
                                                        } else {
                                                            value = rowData[column.field]
                                                        }

                                                        if(column.field === 'horimetroComVirgula') {
                                                            const replace = rowData[column.field].replace(',', '.')
                                                            const inteiro = parseInt(replace)
                                                            if(inteiro > 0) {
                                                                value = (inteiro/3600).toFixed(2).replace('.', ',')
                                                            }
                                                        }
                                                        row.push(value);
                                                    });
                                                    csvData.push(row);
                                                }
                                                // if (rowData.horimetroComVirgula !== '' && rowData.horimetroComVirgula !== '0') {
                                                //     const row = [];
                                                //     colunas.forEach((column) => {
                                                //         const value = rowData[column.field]/3600;
                                                //         row.push(value);
                                                //     });
                                                //     csvData.push(row);
                                                // }
                                            });

                                            const csvHeaders = colunas.map((column) => column.title).join(';');
                                            const csvContents = csvData.map(e => e.join(";")).join("\n");
                                            const csvConverted = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csvHeaders, '\n', csvContents], {
                                                type: 'text/csv;charset=utf-8',
                                            });
                                            const csvURL = window.URL.createObjectURL(csvConverted)
                                            const link = document.createElement("a");
                                            link.setAttribute("href", csvURL);
                                            link.setAttribute("download", "relatório.csv");
                                            document.body.appendChild(link); // Required for FF
                                            link.click();
                                        }
                                    }}
                                    localization={{
                                        pagination: {
                                            labelDisplayedRows: '{from}-{to} de {count}',
                                            backIconButtonText: 'Anterior',
                                            nextIconButtonText: 'Próxima',
                                            labelRowsSelect: 'Linhas',
                                            firstTooltip: 'Primeira página',
                                            previousTooltip: 'Página anterior',
                                            nextTooltip: 'Próxima página',
                                            lastTooltip: 'Última página',
                                            labelRowsPerPage: '{10,50,100}'
                                        },
                                        toolbar: {
                                            nRowsSelected: '{0} linha(s) selecionadas',
                                            searchPlaceholder: 'Filtro',
                                            labelRowsPerPage: 'Linhas por página',
                                            exportTitle: 'Baixar',
                                            exportAriaLabel: 'Baixar',
                                            exportName: 'Baixar como CSV',
                                        },
                                        header: {
                                            actions: ''
                                        },
                                        body: {
                                            emptyDataSourceMessage: 'Nenhum dado encontrado',
                                            filterRow: {
                                                filterTooltip: 'Filter'
                                            },
                                            deleteTooltip: 'Deletar',
                                            editTooltip: 'Editar',
                                            editRow: {
                                                cancelTooltip: 'Cancelar',
                                                saveTooltip: 'Sim',
                                                deleteText: 'Tem certeza que vai deletar?'
                                            }

                                        }
                                    }}
                                // editable={{
                                //     onRowUpdate: oldData =>
                                //         new Promise((resolve, reject) => {
                                //             console.log('Vish');
                                //             // setTimeout(() => {

                                //             // api.delete('/equipamento/' + oldData.idEquipamento).then((response) => {

                                //             //     resolve()

                                //             //     this.setState(prevState => {
                                //             //         const equipaments = [...prevState.equipaments];
                                //             //         equipaments.splice(equipaments.indexOf(oldData), 1);
                                //             //         return { ...prevState, equipaments };
                                //             //     });

                                //             //     if (this.notificationSystem !== undefined)
                                //             //         MessageUtil.showMsg(this.notificationSystem, 200);

                                //             //     this.toggle();
                                //             // }).catch((error) => {
                                //             // console.log('Erro ao deletar o equipamento', error.response);

                                //             //     if (this.notificationSystem !== undefined)
                                //             //         MessageUtil.showMsg(this.notificationSystem, error.response.status);

                                //             //     this.toggle();
                                //             // });


                                //             // }, 1000)
                                //         }),
                                // }}
                                // onRowClick={(event, rowData, togglePanel) => this.setState({ redirectEquipament: true, equipamentClicked: rowData.idEquipamento })}
                                />
                            </Col>
                        </Row>
                    </FormGroup>

                    {/* <Button variant="primary" onClick={this.handleShow}>
                        Launch demo modal
                    </Button> */}

                    {/* {this.state.dadoDuvidoso !== null && */}
                    <Modal show={this.state.show} onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Manutenção</Modal.Title>
                        </Modal.Header>
                        <form onSubmit={this.handleSaveClose}>
                            <Modal.Body>
                                <div className="form-group">
                                    <label htmlFor="mObservacao">
                                        {this.state.mDadoDuvidosoId !== 0 ?
                                            'Remover observação de dado considerado manutenção?' :
                                            'Observação para informar que o dado pode ser considerado como manutenção.'}</label>
                                    <input className="form-control" style={this.state.mDadoDuvidosoId !== 0 ? { display: 'none' } : { display: 'block' }} id="mObservacao" defaultValue={this.state.mObservacao} />
                                </div>
                                {/* <div className="form-group">
                                    <label>Informar se o dado é ou não considerado manutenção</label>
                                </div> */}
                                {/* <div className="form-group">
                                    <input type="radio" id="ddsim" name="mDadoDuvidoso" value="true" defaultChecked="true" />
                                    <label htmlFor="ddsim">Sim</label>
                                    <input type="radio" id="ddnao" name="mDadoDuvidoso" value="false" />
                                    <label htmlFor="ddnao">Não</label>
                                </div> */}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={this.handleClose}>
                                    Fechar
                                </Button>
                                <Button variant="primary" type="submit">
                                    {this.state.mDadoDuvidosoId !== 0 ? 'Remover Dado Duvidoso' : 'Informar Dado Duvidoso'}
                                </Button>
                            </Modal.Footer>
                        </form>
                    </Modal>
                    {/* } */}

                </Page >

                <NotificationSystem
                    dismissible={false}
                    ref={notificationSystem => (this.notificationSystem = notificationSystem)}
                    style={NOTIFICATION_SYSTEM_STYLE}
                />

            </>
        )
    }

}
