export const CONST_MAP = {
  //coord: [-22.253462, -45.700658],
  coord: [-21.619175, -45.250110],
  zoom: 7
}

//export const BACKEND_URL = "http://10.10.10.225:4615";
//export const BACKEND_URL = "http://192.168.1.167:4615";
// export const BACKEND_URL = "https://arteris.ativasolucoes.com.br:4615";
// export const BACKEND_URL = "http://10.10.10.231:4615";
// export const BACKEND_URL = "http://127.0.0.1:4615";
export const BACKEND_URL = "https://backend-arteris.ativasolucoes.com.br";
//export const BACKEND_URL = "http://192.168.2.5:4615";
//export const BACKEND_URL = "http://192.168.1.57:4615";
// em segundos
export const TEMPO_REFRESH_EQUIPAMENTO_VIEW = 60000
export const TIMEOUT_BUSCAR_PORTAS_EQUIPAMENTO = 40000
export const TEMPO_REFRESH_EQUIPAMENTOS = 100000



export const texts = [
  [],//Volume Reservatório
  [],//Nível Poço
  ['Bateria', 'Rede'],//Detecção Rede
  ['Fechada', 'Aberta'],//Porta Aberta
  ['Fechada', 'Aberta'],//Boia Reservatório
  ['Ligada', 'Desligada'],//Botoeira
  [],//Hidrômetro
  ['Desligada', 'Ligada'],//Desligar Bomba
  [],//Tempo Bomba Ligada
];

export const VALOR_HISTORICO_POSICAO = 0

/* RELATORIOS */

export const RELATORIO_POCOS = '5'

///////////////

export const IO_NIVEL_POCO = 1

/* TIPO PORTAS */

export const PORTA_ENTRADA_ANALOGICA = 0;
export const PORTA_ENTRADA_DIGITAL = 1;
export const PORTA_SAIDA_DIGITAL = 2;
export const PORTA_CONTADOR_PULSO = 3;


////////////////

export const NOTIFICATION_SYSTEM_STYLE = {
  NotificationItem: {
    DefaultStyle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: '4px',
      fontSize: '14px',
      margin: '100px 0 0 0',
    },

    success: {
      borderTop: 0,
      backgroundColor: '#45b649',
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },

    error: {
      borderTop: 0,
      backgroundColor: 'rgb(231,22,21)',
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },

    warning: {
      borderTop: 0,
      backgroundColor: '#ffd700',
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },

    info: {
      borderTop: 0,
      background: '#B5B5B5',
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },
  },

  Title: {
    DefaultStyle: {
      margin: 0,
      padding: 0,
      paddingRight: 5,
      color: '#fff',
      display: 'inline-flex',
      fontSize: 20,
      fontWeight: 'bold',
      // left: '15px',
      // position: 'absolute',
      // top: '50%',
    },
  },

  MessageWrapper: {
    DefaultStyle: {
      display: 'block',
      color: '#fff',
      width: '100%',
    },
  },

  Dismiss: {
    DefaultStyle: {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'inherit',
      fontSize: 20,
      color: '#f2f2f2',
      position: 'relative',
      margin: 0,
      padding: 0,
      background: 'none',
      borderRadius: 0,
      opacity: 1,
      width: 20,
      height: 20,
      textAlign: 'initial',
      float: 'none',
      top: 'unset',
      right: 'unset',
      lineHeight: 'inherit',
    },
  },

  Action: {
    DefaultStyle: {
      background: '#fff',
      borderRadius: '2px',
      padding: '6px 20px',
      fontWeight: 'bold',
      margin: '10px 0 0 0',
      border: 0,
    },

    success: {
      backgroundColor: '#45b649',
      color: '#fff',
    },

    error: {
      backgroundColor: '#f85032',
      color: '#fff',
    },

    warning: {
      backgroundColor: '#ffd700',
      color: '#fff',
    },

    info: {
      backgroundColor: '#00c9ff',
      color: '#fff',
    },
  },

  ActionWrapper: {
    DefaultStyle: {
      margin: 0,
      padding: 0,
    },
  },
};


